<template>
  <div
    class="modal-resp-bank">
    <div
      class="container-modal">

      <div
        @click="clickTryAgain"
        class="bottom-close is-flex is-justify-content-flex-end">
        <img
          class=""
          src="https://emituscdn.com/close.svg"
        >
      </div>
      <div
        class="container-content-notification">
        <div
          class="image-notification mt-4">

          <!-- svg image -->

          <svg
            class="image-not-modal"
            width="206"
            height="208"
            viewBox="0 0 206 208"
            fill="none"
          >
            <circle r="102.844" transform="matrix(-1 0 0 1 102.845 102.844)" fill="url(#paint0_linear_2513:196)" fill-opacity="0.68"/>
            <path d="M180.158 116.883C180.158 89.851 158.244 67.9368 131.211 67.9368H117.32C90.2874 67.9368 68.3732 89.851 68.3732 116.883V126.673C68.3732 153.705 90.2874 175.62 117.32 175.62H131.211C158.244 175.62 180.158 153.705 180.158 126.673V116.883Z" fill="url(#paint1_linear_2513:196)" fill-opacity="0.8"/>
            <circle cx="145.549" cy="134.344" r="34.615" fill="#111747"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M164.488 133.692H127.914C127.914 143.07 136.102 150.673 146.201 150.673C156.301 150.673 164.488 143.07 164.488 133.692Z" fill="#42E5D6"/>
            <g style="mix-blend-mode:multiply">
            <path d="M95.3191 185.37C90.1367 188.362 88.3611 194.989 91.3531 200.171L95.454 207.274L99.3011 205.053C104.626 201.979 106.451 195.17 103.376 189.845L99.4245 183L95.3191 185.37Z" fill="#6E3DEA" fill-opacity="0.83"/>
            </g>
            <g style="mix-blend-mode:multiply">
            <path d="M77 196.307C77 202.291 81.8511 207.142 87.8352 207.142L96.0369 207.142L96.0369 202.7C96.0369 196.551 91.0523 191.566 84.9035 191.566L77 191.566L77 196.307Z" fill="#6E3DEA" fill-opacity="0.83"/>
            </g>
            <defs>
            <linearGradient id="paint0_linear_2513:196" x1="183.569" y1="138.754" x2="-40.5058" y2="124.965" gradientUnits="userSpaceOnUse">
            <stop stop-color="#9A55F2"/>
            <stop offset="1" stop-color="#A7E0DE" stop-opacity="0.65"/>
            </linearGradient>
            <linearGradient id="paint1_linear_2513:196" x1="124.266" y1="67.9368" x2="124.266" y2="175.62" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0.7"/>
            </linearGradient>
            </defs>
          </svg>

        </div>
        <div class="right-container-desktop">
          <p
            class="mt-2 title-modal dark-blue-emitus text-basier">
            Transaction in process
          </p>
          <p
            class="paragraph-content dark-gray-emitus mt-4">
            We are waiting for your bank to approve the transaction, this should not take too long.
          </p>

          <div
            class= "info-booking line-top-gray line-bottom-gray">

            <p
              class="mt-2 text-basier-medium dark-blue-emitus">
              Session with {{preBooking.professional_name}}
            </p>
            <div
              class="is-flex is-justify-content-space-between is-align-items-flex-end mt-5">
              <p
                class="text-nunito dark-gray-emitus">
                {{dateBooking}}
              </p>
              <p
                class="text-nunito-Regular size-medium-text">
                {{preBooking.price}} {{preBooking.currency === 'USD' ? '$' : '€'}}
              </p>
            </div>

            <div
              class="mt-3 is-flex is-justify-content-space-between">
              <p
                class="text-nunito dark-gray-emitus">
                {{hourInitBooking}} - {{hourEndBooking}} (GTM -5)
              </p>
              <p
                class="text-nunito gray-emitus text-size-small">
                {{preBooking.duration}} min
              </p>
            </div>
          </div>

          <!-- vif modal-->

          <div
            class="login">

          </div>

          <!-- transaction approved -->

          <div
            v-if="paySuccess && !loader"
            class="mt-5">
            <div
              class=" mt-6 is-flex is-align-items-center">
              <img
                src="https://emituscdn.com/approved-icon.svg"
              >
              <span
                class="ml-3 text-basier-medium size-medium-text blue-emitus">
                Transaction Approved ✌️
              </span>
            </div>
            <div
              class="mt-5">
              <button
                @click="clickNice"
                class="button-primary-extra-medium text-basier-medium">
                Nice! continue
              </button>
            </div>
          </div>
          <!-- transaction declined-->
          <div
            v-if="payFailed && !loader"
            class="mt-5">
            <div
              class="is-flex is-align-items-center">
              <img
                src="https://emituscdn.com/declined-icon.svg"
              >
              <p
                class="ml-3 dark-red-emitus text-basier-medium size-medium-text">
                Transaction Declined 😔️
              </p>
            </div>
            <div
              class="mt-5">
              <button
              @click="clickTryAgain"
                class="button-primary-extra-medium text-basier-medium">
                Try Again
              </button>
            </div>
          </div>
          <div v-if="loader" class="is-flex is-align-items-center" style="margin-top: 15px">
            <div class="lds-dual-ring"><div></div></div>
            <p class="text-basier" style="color: #6E3DEA;">Waiting...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import UserService from '@/services/UserService'
import PrebookingService from '@/services/PrebookingService'
import MpService from '@/services/MpService'
import BookingService from '@/services/BookingService'
import dayjs from 'dayjs'

export default {
  name: 'Payment3dsecure',
  data () {
    return {
      user: null,
      authUser: null,
      payFailed: false,
      paySuccess: false,
      loader: false,
      username: '',
      preBooking: {},
      dateBooking: null,
      hourInitBooking: null,
      hourEndBooking: null
    }
  },
  async created () {
    // username profesional public profileActive
    this.notify.$on('usernamePayments3ds', data => {
      this.username = data
    })
    this.loader = true
    this.notify.$emit('hiddeNavbarPayBank')
    // Get user from Firebase
    firebase.auth().onAuthStateChanged(user => {
      this.authUser = user
    })

    // Get User
    const idToken = await firebase.auth().currentUser.getIdToken(true)
    const user = await UserService.getCurrentUser(idToken)
    this.user = user.data

    // Get info about booking
    const prebookingData = await PrebookingService.getPrebooking(idToken, this.$route.params.prebookingId)
    const prebooking = prebookingData.data
    this.preBooking = prebooking
    this.hourEndBooking = dayjs(this.preBooking.endBook).format('HH:mm')
    this.hourInitBooking = dayjs(this.preBooking.startBook).format('HH:mm')
    this.dateBooking = dayjs(this.preBooking.startBook).format('DD MMMM YY')

    // Wait for bank to respond
    let counter = 0
    const IntervalID = setInterval(async () => {
      counter++

      const preAuth = await MpService.getPreAuth(idToken, prebooking.mppreauthid)
      if (preAuth.data.Status === 'SUCCEEDED') {
        this.notification('Preautorización exitosa')
        await this.makeBookingRequest(prebooking)
        clearInterval(IntervalID)
      } else if (preAuth.data.Status === 'FAILED' || counter === 100) {
        this.payFailed = true
        this.loader = false
        this.danger('Pago Fallido')
        // this.loader = false
        clearInterval(IntervalID)
      }
    }, 4000)

    // Este objeto es el que hay que crear
    // const bookingObj = {
    //   professional_id: this.professionalId,
    //   startBook: this.startBook,
    //   endBook: this.endBook,
    //   duration: this.duration,
    //   price: obj.price,
    //   currency: obj.currency,
    //   language: this.language,
    //   mppreauthid: obj.mppreauthid
    // }
  },
  methods: {
    // Send request
    async makeBookingRequest (prebooking) {
      const timezone = dayjs.tz.guess()

      // console.log(timezone)
      try {
        const idToken = await firebase.auth().currentUser.getIdToken(true)
        BookingService.BookingRequest(idToken, timezone, prebooking)
          .then(() => {
            this.notification('Solicitud de reserva exitosa')
            this.paySuccess = true
            this.loader = false
            // this.showSuccess = true
          })
          .catch(error => {
            console.log(error, error.response)
            this.danger(error.response.data.msg)
            this.payFailed = true
            this.loader = false
            // this.showError = true
          })
      } catch (error) {
        console.error('Conection Error', error.response.data.msg)
        this.payFailed = true
        this.loader = false
      }
    },
    clickTryAgain () {
      // this.$router.push('/' + this.preBooking.professional_username)
      location.href = '/' + this.preBooking.professional_username
    },
    clickNice () {
      // this.$router.push('/panel/booking')
      location.href = '/panel/booking'
    },

    // Susscess Toast
    notification (msg) {
      this.$buefy.toast.open({
        duration: 1000,
        message: msg,
        position: 'is-top-right',
        type: 'is-success'
      })
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-danger'
      })
    }

  }

}
</script>

<style scoped>
.modal-resp-bank {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  background: #fff;
}

.container-modal {
  width: 85%;
  margin: 35px auto;
}

.container-content-notification {
  width: 95%;
  display: flex;
  flex-direction: column;
  max-width: 628px;
  margin: auto;
}

.image-notification svg {
  width: 130px;
  height: 126px;
}

.title-modal {
  font-size: 27px;
  max-width: 175px;
  line-height: 37px;
}

.line-top-gray {
  border-top: 1px solid #ECECEC;
  padding-top: 10px;
}

.line-top-gray {
  border-bottom: 1px solid #ECECEC;
  padding-bottom: 10px;
}

.info-booking {
  margin-top: 30px;
}

.size-medium-text {
  font-size: 17px;
}

.text-size-small {
  font-size: 13px;
}

@media (min-width: 1024px) {
.container-content-notification {
  flex-direction: row;
}
.image-notification svg {
  width: 205px;
  height: 207px;
}

.right-container-desktop {
  margin-top: 111px;
  margin-left: 34px;
}

}
</style>

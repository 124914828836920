import { render, staticRenderFns } from "./payments3ds.vue?vue&type=template&id=6f59b3c0&scoped=true&"
import script from "./payments3ds.vue?vue&type=script&lang=js&"
export * from "./payments3ds.vue?vue&type=script&lang=js&"
import style0 from "./payments3ds.vue?vue&type=style&index=0&id=6f59b3c0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f59b3c0",
  null
  
)

export default component.exports
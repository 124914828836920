import axios from 'axios'
import MixpanelService from '@/services/MixpanelService'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  async BookingRequest (idToken, timezone, bookingObj) {
    const response = await apiClient.post('/bookings/', bookingObj,
      {
        headers: { idToken: idToken }
      })

    if (response.status === 200) {
      MixpanelService.bookingRequest(response.data.nameProfessional, response.data.booking)
    }

    return response
  },
  async getMyBookingsAsProfessional (idToken) {
    return await apiClient.get('bookings/professional',
      {
        headers: { idToken: idToken }
      })
  },
  async getMyBookingsAsClient (idToken) {
    return await apiClient.get('bookings/client',
      {
        headers: { idToken: idToken }
      })
  },
  async getBooking (idToken, bookingId) {
    return await apiClient.get(`bookings/${bookingId}`,
      {
        headers: { idToken: idToken }
      })
  },
  async approveBooking (idToken, bookingId) {
    const response = await apiClient.post(`bookings/approve/${bookingId}`, {},
      {
        headers: { idToken: idToken }
      })

    if (response.data.msg === 'Booking successfully approved & notifications were sent to the client') {
      MixpanelService.bookingAproval(response.data.nameProfessional, response.data.booking)
    }

    return response
  },
  async cancelBookingBeforeApproval (idToken, bookingId, whocancel, cancelreason) {
    return await apiClient.post(`bookings/cancelBeforeApproval/${whocancel}/${bookingId}`,
      {
        cancelreason: `${cancelreason}`
      },
      {
        headers: { idToken: idToken }
      })
  },
  async cancelBookingAfterApproval (idToken, bookingId, whocancel, cancelreason) {
    return await apiClient.post(`bookings/cancelAfterApproval/${whocancel}/${bookingId}`,
      {
        cancelreason: `${cancelreason}`
      },
      {
        headers: { idToken: idToken }
      })
  },
  async cancelBooking (idToken, bookingId, whocancel, cancelreason) {
    return await apiClient.post(`bookings/cancel/${whocancel}/${bookingId}`,
      {
        cancelreason: `${cancelreason}`
      },
      {
        headers: { idToken: idToken }
      })
  },
  async getMyBookingInfoProfessional (idToken, bookingId) {
    return await apiClient.get(`bookings/getBookingInfo/professional/${bookingId}`,
      {
        headers: { idToken: idToken }
      })
  },
  async getMyBookingInfoClient (idToken, bookingId) {
    return await apiClient.get(`bookings/getBookingInfo/client/${bookingId}`,
      {
        headers: { idToken: idToken }
      })
  },
  async BookingReschedule (idToken, bookingId, bookingObj) {
    return await apiClient.post(`bookings/reschedule/client/${bookingId}`, bookingObj,
      {
        headers: { idToken: idToken }
      })
  },
  // async deliveredSession (idToken, bookingId) {
  //   return await apiClient.put(`bookings/deliver/client/${bookingId}`, {},
  //     {
  //       headers: { idToken: idToken }
  //     })
  // },
  async checkOverlaps (idToken, timezone, bookingObj) {
    const response = await apiClient.post('/bookings/checkOverlaps', bookingObj,
      {
        headers: { idToken: idToken }
      })
    if (response.data.result === 'ok') {
      return true
    } else {
      return false
    }
  },
  async clientRateSession (idToken, bookingId, ratingObj) {
    return await apiClient.post(`bookings/rate/client/${bookingId}`, ratingObj,
      {
        headers: { idToken: idToken }
      })
  },
  async professionalRateSession (idToken, bookingId, ratingObj) {
    return await apiClient.post(`bookings/rate/professional/${bookingId}`, ratingObj,
      {
        headers: { idToken: idToken }
      })
  }

}
